@font-face {
  font-family: 'icomoon';
  src:  url('../../fonts/icomoon.eot?hroloi');
  src:  url('../../fonts/icomoon.eot?hroloi#iefix') format('embedded-opentype'),
    url('../../fonts/icomoon.ttf?hroloi') format('truetype'),
    url('../../fonts/icomoon.woff?hroloi') format('woff'),
    url('../../fonts/icomoon.svg?hroloi#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    display: block;
    font-size: 30px;
    margin-top: 5px
}

.icon-uitlijnen:before {
  content: "\e900";
}
.icon-herstellen:before {
  content: "\e901";
}
.icon-banden:before {
  content: "\e902";
}
.icon-balanceren:before {
  content: "\e903";
}


* {
    outline: none;
}
body {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 15px;
    background-color: #fff;
}
a,button{
    transition:0.5s all;
    -webkit-transition:0.5s all;
    -moz-transition:0.5s all;
    -ms-transition:0.5s all;
    -o-transition:0.5s all;
}
a{
    text-decoration:none;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
}
a:focus,a:hover,a:active{
    outline: 0;
    box-shadow: 0;
}
a:focus, a:hover{
    text-decoration: none;
    color: #000;
}
.h1, .h2, .h3, h1, h2, h3, .h4, .h4, .h5, h5, h6, h6 {
    margin-bottom: 0;
    margin-top: 0;
}
h1{
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 30px;
}
h2{
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 26px;
}
h2 a{
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 26px;
}
h3{
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 15px;
}
h3 a{
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 18px;
}
h4{
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 16px;
}
h5{
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 14px;
}
h6{
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 14px;
}
a{
    text-decoration: none;
    font-family: 'Roboto', sans-serif;
}
a:hover{
    color: #000;
    text-decoration: none;
}
ul{
    margin: 0;
    padding: 0;
}
ul li{
    list-style: none;
    font-family: 'Roboto', sans-serif;
}
p{
    margin: 0;
    margin-bottom: 15px;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
}
input:focus,
select:focus,
textarea:focus{
    outline: none;
}
.child-pernt {
    display: table;
    height: 100%;
    width: 100%;
}
.child {
    display: table-cell;
    vertical-align: middle;
}
.section-padding{
    padding: 60px 0;
}
.btn:focus {
    outline: none;
}
.btn.active, .btn:active{
    box-shadow: none;
}
.wrapper{
    overflow: hidden;
}
/*========================================
           Header
=========================================*/
.maak-top{
    text-align: right;
}
.maak-top h3{
    color: #035992;
    text-transform: uppercase;
    font-size: 18px;
}
.maak-top h3 span{
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
}
.maak-top h3 img{
    vertical-align: middle;
    width: 25px;
}
/*===============
   Mid Header
================*/
.mind-header{
    text-align: center;
}
.mind-header .logo{
    display: inline-block;
    margin-top: 30px;
    height: 120px;
}
.mind-header .logo img{
    height: 100%;
    width: auto;
}
/*===============
   Last Header
================*/
.last-header{
    margin-top: 25px;
    padding-bottom: 30px;
    border-bottom: 2px solid #494949;
}
.navigation {
    text-align: center;
}
.navigation .navbar-default{
    background-color: transparent;
    border-color: transparent;
    margin-bottom: 0;
}
.navigation .navbar {
    border: medium none;
    border-radius: 0;
    min-height: inherit;
}
.navigation .navbar-nav {
    float: none;
    margin: 0;
    display: inline-block;
}
.navigation .nav > li {
    padding: 0 23px;
}
.navigation .nav > li > a{
    padding: 0;
    font-size: 18px;
    color: #000;

    font-weight: 400;
    text-transform: uppercase;
}
.navigation .nav > li > a:hover{
    color: #035992;
    font-weight: bolder;
}
.navigation .navbar-default .navbar-nav > .active > a, .navigation  .navbar-default .navbar-nav > .active > a:focus,  .navigation  .navbar-default .navbar-nav > .active > a:hover{
    color: #035992;
    background-color: transparent;
    font-weight: bolder;
}
.navigation .nav > li > a.maak-uw-afspraak {
    display: inline-block;
    background: #fede00;
    padding: 12px 15px;
    margin-top: -12px;
    font-weight: bolder;
}
.navigation .navbar-default .navbar-nav > .active > a.maak-uw-afspraak, 
.navigation  .navbar-default .navbar-nav > .active > a.maak-uw-afspraak:focus,
.navigation  .navbar-default .navbar-nav > .active > a.maak-uw-afspraak:hover{
    display: inline-block;
    background: #fede00;
    padding: 12px 15px;
    margin-top: -12px;
    font-weight: bolder;
    color: #000;
}
.navigation .nav > li > a.maak-uw-afspraak i {
    margin-right: 10px;
}
.navigation .nav > li > a.maak-uw-afspraak:hover {
    font-weight: 900;
    color: #000;
}
/*================================
            Banner
==================================*/
.banner{
    margin-top: 22px;
    height: 450px;
}
.inner-banner .image-holder{
    position: relative;
    height: 450px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.inner-banner .image-holder .overlay{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}
.inner-banner .text-box{
    position: absolute;
    top: 40%;
    left: 0;
    width: 100%;
    text-align: center;
    color: #fff;
}
.text-inner{
    display: inline-block;
    padding: 18px 28px;
}
.text-inner p{
    color: #000;
    font-size: 18px;
    color: #fff;
}
.inner-banner .owl-theme .owl-controls {
    margin-top: 0;
    text-align: center;
    -webkit-tap-highlight-color: transparent;
}
.owl-theme .owl-controls .owl-buttons div {
    border-radius: 0;
    background: #fff;
    border: 1px solid #111;
    color: #111;
    font-size: 27px;
    padding: 5px 14px;
    margin-top: -30px;
    position: absolute;
    left: -25px;
    top: 50%;
    opacity: 1;
}
.owl-theme .owl-controls .owl-buttons div:hover {
    background: #111;
    color: #fff;
}
.owl-theme .owl-controls .owl-buttons div.owl-next {
    left: auto;
    right: -25px;
}
.owl-theme .owl-controls .owl-buttons div.owl-prev:after {
    content: '';
    position: absolute;
    bottom: -21px;
    left: -1px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 20px 20px 0;
    border-color: transparent #111 transparent transparent;
}
.owl-theme .owl-controls .owl-buttons div.owl-next:after {
    content: '';
    position: absolute;
    bottom: -21px;
    right: -1px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 20px 20px 0 0;
    border-color: #111 transparent transparent transparent;
}

/*================================
         Audi Welke Band
==================================*/
.audi-welke-band{
    padding: 25px 0 50px 0;
}
.audi-welke-band-left h1{
    margin-bottom: 33px;
    text-transform: uppercase;
}
.audi-zoeken .nav-tabs, .audi-zoeken .nav-tabs>li.active>a, .audi-zoeken .nav-tabs>li.active>a:focus, .audi-zoeken .nav-tabs>li.active>a:hover {
    border-color: #fff;
    color: #035992;
}
.nav-tabs>li>a:hover,
.nav-tabs>li>a:focus {
    background: #fff;
    border-color: #fff;
}
.audi-zoeken .nav>li.advanced>a {
    padding-left: 0;
    margin-right: 7.5px;
}
.audi-zoeken .nav>li.quick>a {
    margin-left: -5.5px;
}
.audi-zoeken .nav>li.dash {
    padding-top: 10px;
    color: #000;
    margin-left: 13px;
}
.audi-zoeken .nav-tabs>li.active>a h3, 
.audi-zoeken .nav-tabs>li.active>a:hover h3,
.nav-tabs>li>a:hover h3 {
    color: #035992;
}
.audi-zoeken .nav-tabs {
    border-bottom: 2px solid #f4f4f6;
    margin-bottom: 20px;
}
.audi-zoeken{
    border: 1px solid #f6f6f6;
    -webkit-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.1);
    box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.1);
    padding: 30px 30px 40px;
    background: url('../../img/background_search_5pr.jpg') no-repeat center;
    background-size: cover;
}
.audi-zoeken .col-sm-2 {
    padding-left: 5px;
    padding-right: 5px;
}
.audi-zoeken h3{
    color: #000;
    padding-bottom: 15px;
}
.audi-zoeken form .form-group label{
    color: #677172;
    display: block;
    margin-bottom: 13px;
    font-size: 14px;
    font-weight: 500;
}
.audi-zoeken form .form-group select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #ebecee url("../../img/select-arrow.jpg") no-repeat scroll 90% center;
    border: 1px solid #dcdcdc;
    border-radius: 0;
    font-size: 14px;
    font-weight: 400;
    margin-right: 8px;
    padding: 12px 35px 12px 8px;
}
.audi-zoeken form .form-group button{
    color: #333;
    background-color: #035992;
    border: 1px solid #035992;
    text-transform: uppercase;
    border-radius: 0;
    margin-top: 33px;
    padding: 12px 21px;
    display: inline-block;
    color: #fff;
    width: 100%;
}
.audi-zoeken form .form-group button:hover{
     background-color: #2c2c2c;
    border: 1px solid #2c2c2c;
    color: #fff;
}
.cars-repairs {
    margin-top: 50px;
}
.cars-repairs h3{
    margin-bottom: 20px;
}
.cars-repairs p{
    color: #000;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 15px;   
}
.cars-repairs ul li,
.onderhoud-start ul li,
.about-inner ul li {
    color: #000;
    background: rgba(0, 0, 0, 0) url("../../img/cheack-icon.png") no-repeat scroll 0 5px;
    font-weight: 400;
    padding-bottom: 10px;
    padding-left: 36px;
}
.cars-repairs .image-holder{
    margin-top: 35px;
}
.prevent-oil {
    background-color: #035992;
    border-bottom: 1px solid #000;
    margin-top: 50px;
}
.prevent-oil .image-holder {
    width: 50%;
    height: 336px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    float: left;
}
.prevent-oil .text-box{
    width: 50%;
    padding: 30px 30px;
    float: left;
    color: #fff;
}
.prevent-oil.blue {
    background-color: #035992;
}
.prevent-oil.black {
    background-color: #494949;
}
.prevent-oil.yellow {
    background-color: #fede00;
}
.prevent-oil.gray {
    background-color: #d9d9d9;
}
.prevent-oil.yellow .text-box,
.prevent-oil.gray .text-box {
    color: #494949;
}



/*================================
        Right Side
==================================*/
.right-tires{
    -webkit-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.1);
    box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.1);
    padding: 2px;
}
.right-tires .right-tires-left{
    float: left;
    width: 30%;
}
.right-tires-left .nav-tabs {
    border-bottom: medium none;
}
.right-tires-left .nav-tabs > li {
    float: none;
    margin-bottom: 2px;
}
.right-tires-left .nav-tabs > li > a {
    border: none;
    border-radius: 0;
    margin-right: 0;
}
.right-tires-left .nav-tabs > li > a img{
    display: block;
    width: 28px;
    margin: 0 auto;
}
.right-tires-left .nav > li > a{
    padding: 15px 0;
    text-align: center;
    color: #d9d9d9;
    font-weight: 500;
    text-transform: uppercase;
    background-color: #035992;
}
.right-tires-left .nav-tabs > li.active > a, .right-tires-left .nav-tabs > li.active > a:focus, .right-tires-left .nav-tabs > li.active > a:hover{
    border: none;
    background-color: transparent;
    color: #000;
}
.right-tires .right-tires-right{
    float: left;
    padding: 10px;
    width: 70%;
}
.right-tires-right .panel-default > .panel-heading {
    background-color: transparent;
    border-color: transparent;
}
.right-tires-right .panel-heading {
    border-bottom: medium none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
     padding: 10px 0;
}
.right-tires-right  .panel-group .panel{
    border-radius: 0;
    border: none;
    border-bottom: 1px solid #ccc;
    box-shadow: none;
}
.right-tires-right  .panel-group .panel.no-border{
    border: none;
}
.right-tires-right .panel-body {
    padding: 0 0 10px;
}
.right-tires-right .panel-group .panel-heading + .panel-collapse > .list-group, .right-tires-right  .panel-group .panel-heading + .panel-collapse > .panel-body {
    border-top: none;
}
.editore-pick{
    -webkit-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.1);
    box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.1);
    margin-top: 40px;
    padding: 30px;
}
.editore-pick h2{
    text-transform: uppercase;
    margin-top: -44px;
}
.editore-pick-colum{
    margin-top: 30px;
}
.editore-pick-colum h3{
    text-transform: uppercase;
    padding-bottom: 15px;
}
.editore-pick-colum .text-box{
    background-color: #fff;
    margin: -35px 15px 0;
    padding: 15px;
    position: relative;
}
.editore-pick-colum .text-box h4{
    margin: 0;
    line-height: 24px;
}
/*================================
        Need Help Sec
==================================*/
.need-help-sec{
    text-align: center;
    padding-bottom: 60px;
}
.need-help-sec .hedding p{
    font-weight: 400;
    font-size: 15px;
}
.help-cal{
    margin-top: 35px;
}
.help-cal h3{
    margin-bottom: 36px;
}
.help-cal h3 a.inline,
.help-cal h3 a.inline:hover {
    color: #333;
    background-color: transparent;
    font-weight: 900
}
.help-cal a{
    display: inline-block;
    text-transform: uppercase;
    color: #000;
    background-color: #fede00;
    padding: 18px 44px;
    font-weight: 500;
    margin-right: 10px;
}
.help-cal a:hover{
    background-color: #2c2c2c;
    color: #fff;
}
.help-cal a:last-child{
    background-color: #2c2c2c;
    color: #fff;
}
.help-cal a:last-child:hover{
    color: #000;
    background-color: #fede00;
}
/*================================
        Footer
==================================*/
.footer {
    background: url('../../img/footer.jpg') no-repeat center;
    background-size: cover;
}
.footer-inner{
    border-top: 2px solid #e7e7e7;
    padding-top: 55px;
}
.footer-colum h3{
    text-transform: uppercase;
    padding-bottom: 20px;
}
.footer ul li {
    color: #787878;
}
.footer-colum ul li a{
    color: #787878;
    text-transform: capitalize;
    margin-bottom: 7px;
    display: block;
    font-weight: 400;
}
.footer-colum ul li a:hover{
    color: #035992;
}
.footer-colum p{
    color: #787878;
    font-weight: 400;
    margin-bottom: 7px;
}
.footer-colum form input[type="text"]{
    border: 1px solid #dcdcdc;
     border-radius: 0;
    color: #787878;
    font-weight: 500;
    height: 44px;
    padding-left: 10px;
    width: 170px;
}
.footer-colum form input[type="submit"]{
    background-color: #3b3b3b;
    color: #fff;
    border: 1px solid #3b3b3b;
    font-weight: 500;
    padding: 10px 15px;
    transition:  all 0.5s ease-in-out;
     border-radius: 0;
}
.footer-colum form input[type="submit"]:hover{
    background-color: #fede00;
    color: #000;
    border: 1px solid #fede00;
    transition:  all 0.5s ease-in-out;
}
/*================================
        Onderhoud
==================================*/
.onderhoud-sec{
    padding: 65px 0 100px 0;
}
.onderhoud-catesories{
    -webkit-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.1);
    box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.1);
     padding: 30px;
    text-align: left;
}
.onderhoud-catesories h2 {
    text-transform: uppercase;
    margin-top: -44px;
}

.onderhoud-catesories .nav-tabs {
    border-bottom: none;
}
.onderhoud-catesories .nav-tabs > li h3 a {
    border: medium none;
    border-radius: 0;
    line-height: 1.42857;
    margin-right: 0;
    text-transform: uppercase;
    color: #000;
    padding: 30px 0 0 0;
    border-bottom: 1px solid #f1f3f2;
    display: block;
}
.onderhoud-catesories .nav-tabs > li > a:hover{
    background-color: transparent;
}
.onderhoud-catesories .nav-tabs > li.active > a, .nav-tabs > li.active > a:focus, .onderhoud-catesories .nav-tabs > li.active > a:hover{
    border: none;
    color: #035992;
    border-bottom: 1px solid #f1f3f2;
}
.onderhoud-catesories .nav-tabs > li.active h3 a{
    color: #035992;
}
.onderhoud-catesories .nav-tabs > li h3 a:hover{
     color: #035992;
}
.onderhoud-catesories .nav-tabs > li {
    float: none;
    margin-bottom: 0;
}
.onderhoud-start h2{
    margin-bottom: 33px;
    text-transform: uppercase;
}
.onderhoud-start .prevent-oil {
    margin-top: 0;
}
.nderhoud-start-lorm{
    margin-bottom: 30px;
    margin-top: 30px;
}
.nderhoud-start-lorm h3{
    padding-bottom: 20px;
}
.nderhoud-start-lorm p{
    color: #000;
    font-weight: 400;
    line-height: 24px;
}
/*================================
        Wie zijn wij
==================================*/
.about-sec{
    padding: 65px 0 100px 0;
}
.about-inner .hedding{
    padding-bottom: 50px;
    text-align: center;
}
.about-inner .hedding h2{
    color: #000;
}
.about-inner .hedding p{
    color: #000;
    font-size: 15px;
    font-weight: 400;
}
.about-inner .text-box p{
    font-weight: 400;
    color: #000;
    line-height: 24px;
    margin-top: 25px;
}
.Meet-the-owners{
    margin-top: 70px;
}
.meet-the-owners-inner{
    -webkit-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.1);
    box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.1);
}
.meet-the-owners-inner .image-holder{
    float: left;
    width: 50%;
    height: 375px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.meet-the-owners-inner .text-clints{
    float: left;
    width: 50%;
    padding: 50px 35px;
}
.meet-the-owners-inner .text-clints h3{
    text-transform: uppercase;
    padding-bottom: 12px;
}
.meet-the-owners-inner .text-clints span{
    font-weight: 500;
    font-size: 14px;
    display: block;
    text-transform: capitalize;
    padding-bottom: 15px;
}
.meet-the-owners-inner .text-clints p{
    color: #000;
    margin-bottom: 33px;
}
.meet-the-owners-inner .text-clints ul li{
    display: inline-block;
    margin-right: 5px;
}
.meet-the-owners-inner .text-clints ul li a{
    background-color: #035992;
    border-radius: 50%;
    width: 41px;
    color: #fff;
    font-size: 16px;
    text-align: center;
    padding-top: 9px;
    height: 41px;
    display: inline-block;
}
.about-cntant{
    margin-top: 50px;
}
.about-cntant p{
    font-size: 15px;
    line-height: 24px;
    font-weight: 400;
}






input[type=number],
input[type=text],
textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #ebecee;
    border: 1px solid #dcdcdc;
    border-radius: 0;
    font-size: 14px;
    font-weight: 400;
    margin-right: 8px;
    padding: 12px 8px 12px 8px;
    width: 100%;
}
textarea {
    height: 100px;
}
select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #ebecee url("../../img/select-arrow.jpg") no-repeat scroll right 20px center;
    border: 1px solid #dcdcdc;
    border-radius: 0;
    font-size: 14px;
    font-weight: 400;
    margin-right: 8px;
    padding: 12px 35px 12px 8px;
    width: 100%;
}

.search h1 {
    display: none;
}



.autobanden .header .check {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-top: 40px;
    background: url('../../img/cheack-icon-blue.jpg') no-repeat center;
    background-size: contain;
}
.cart th, .autobanden th {
    color: #677172;
    padding-bottom: 13px;
    font-size: 14px;
    font-weight: 500;
}
.autobanden .size {
    background: #d9d9d9;
    color: #494949;
    display: inline-block;
    padding: 10px;
    margin: 20px 0;
}

.autobanden tr td {
    border-top: 2px solid #f4f4f6;
    padding: 20px;
}
.autobanden tr:first-child td {
    border-top: none;
}
.autobanden tr td img {
    height: 100%;
    width: auto;
    max-height: 200px;
}
.autobanden tr td .price {
    display: block;
    font-size: 24px;
}
.autobanden tr td .season {
    position: relative;
}
.autobanden tr td .season .name {
    padding: 10px 10px 10px 50px;
    display: inline-block;
}
.autobanden tr td .season .icon {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 0;
    left: 0;
}
.autobanden tr td .season #summer {
    background: url('../../img/sun.png') no-repeat center center;
    background-size: cover;
}
.autobanden tr td .season #winter {
    background: url('../../img/snow.png') no-repeat center center;
    background-size: cover;
}
.autobanden tr td .season #all-season {
    background: url('../../img/all-season.png') no-repeat center center;
    background-size: cover;
}
.autobanden tr td .block {
    position: relative;
    display: inline-block;
    margin-top: 20px;
    margin-right: 10px;
    padding: 0 10px;
    background: #035992;
    color: #fff;
}
.autobanden tr td .block .name {
    padding: 5px 5px 5px 30px;
    display: inline-block;
}
.autobanden tr td .block .icon {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 5px;
    left: 12px;
}
.autobanden tr td .block #fuel {
    background: url('../../img/fuel.png') no-repeat center center;
    background-size: cover;
}
.autobanden tr td .block #wet {
    background: url('../../img/rain.png') no-repeat center center;
    background-size: cover;
}
.autobanden tr td .block #decibel {
    background: url('../../img/noice.png') no-repeat center center;
    background-size: cover;
}
.autobanden tr td a:not(.remove) {
    display: inline-block;
    color: #333;
    background-color: #fede00;
    border: 1px solid #fede00;
    text-transform: uppercase;
    border-radius: 0;
    margin-top: 10px;
    padding: 12px 21px;
}
.autobanden tr td a:not(.remove):hover{
     background-color: #2c2c2c;
    border: 1px solid #2c2c2c;
    color: #fff;
}

.autobanden tr td a.remove {
    color: #f00;
}
.autobanden tr td a.remove:hover {
    color: #333;
}
.autobanden button,
.agenda button {
    color: #333;
    background-color: #fede00;
    border: 1px solid #fede00;
    text-transform: uppercase;
    border-radius: 0;
    margin-top: 33px;
    padding: 12px 21px;
    display: inline-block;
    color: #333;
}
.autobanden button:hover,
.agenda button:hover {
    background-color: #2c2c2c;
    border: 1px solid #2c2c2c;
    color: #fff;
}

.steps {
    width: 100%;
    margin: 0;
    background-color: #ebecee;
    color: #494949;
    padding: 0;
}
.steps .step {
    display: inline-block;
    padding: 20px 0;
    text-align: center;
    border-right: 1px solid #494949;
}
.steps .step:last-child {
    border-right: none;
}

.steps .step.active {
    color: #fff;
    background-color: #035992;
}




.agenda .date-wrapper {
    border-bottom: 2px solid #f4f4f6; 
    padding: 10px;
}
.agenda .calender-header {
    border-bottom: 2px solid #f4f4f6; 
}
.agenda .date-label {
    display: inline-block; 
    background: #ebecee; 
    color: #494949;
    text-align: center; 
    font-weight: 500;
    position: relative;
    margin: 0 2px 4px;
}
.agenda span {
    padding: 10px;
    display: block;
}
.agenda input[type=radio], .agenda input[type=checkbox] {
    opacity: 0; 
    position: absolute; 
    top: 0;
    left: 0;
}
.agenda input[type="checkbox"]:checked+label, .agenda input[type="radio"]:checked+label{ background-color: green; color: white } 
.agenda input[type="checkbox"]:disabled+label, .agenda input[type="radio"]:disabled+label{ background-color: red; color: white; text-decoration:line-through } 
.agenda input[type="checkbox"]:checked+label.current{ background-color: #fede00; color: white } 
.agenda input[type="checkbox"]:checked+label.current.line-true{ text-decoration:line-through } 

.agenda .calender-header {
    position: relative;
    text-align: center;
    padding: 0 0 20px;
}
.agenda .calender-header h2 {
    color: #000;
    font-weight: bold;
}
.agenda .calender-header .change-month {
    position:absolute;
    top: 0;
    color: #035992;
    font-weight: bold;
}
.agenda .calender-header .change-month#next {
    right: 15px;
}
.agenda .calender-header .change-month#prev {
    left: 15px;
}


.service {
    padding: 5px; 
    margin: 5px; 
    border: 1px solid #e7eaec;
}

.flat {
    width: 100%;
    height: 150px;
    margin: 40px 0 124px 0;
    background: url('../../img/flat_tire.png') no-repeat center;
    background-size: contain;
}


.filter {
    display: none;
}
.filter-label {
    content: '';
    display: inline-block;
    width: 40px;
    height: 40px;
    margin-left: 10px;
    border: 2px solid rgb(246, 246, 246);
}
.filter-label.summer {
    background: url(../../img/sun.png) no-repeat center center ;
    background-size: cover;
}
.filter-label.winter {
    background: url(../../img/snow.png) no-repeat center ;
    background-size: cover;
}
.filter-label.all-season {
    background: url(../../img/all-season.png) no-repeat center ;
    background-size: cover;
}




/*
.cart .shoppingcart {
    border: 1px solid #f6f6f6;
    -webkit-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.1);
    box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.1);
    padding: 66px 30px 40px;
}
.cart tr td img {
    height: 100%;
    width: auto;
    max-height: 100px;
}
.cart tr td .size {
    margin: 20px 0;
}
.cart tr td .totalprice {
    display: block;
    font-size: 24px;
}
.cart tr td .price {
    display: block;
    font-size: 1.5em;
}
.cart tr td .season {
    display: inline-block;
    width: 25px;
    height: 25px;
}
.cart tr td .season.summer {
    background: url('../../img/sun.png') no-repeat center center;
    background-size: cover;
}
.cart tr td .season.winter {
    background: url('../../img/snow.png') no-repeat center center;
    background-size: cover;
}
*/