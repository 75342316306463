@media (max-width: 1199px){
    .top-nav ul li a {
        padding: 0 5px;
    }
    .audi-zoeken form .form-group select {
        font-size: 12px;
        margin-right: 5px;
        padding: 12px 25px 12px 5px;
    }
    .audi-zoeken form .form-group a {
        padding: 12px 9px;
    }
    .prevent-oil .text-box {
        padding: 11% 0;
    }
    .cars-repairs {
        margin-top: 155px;
    }
    .meet-the-owners-inner .text-clints {
        padding: 15px;
    }
    .meet-the-owners-inner .text-clints p {
        margin-bottom: 20px;
    }
}
@media (max-width: 991px){
    h1{
        font-size: 30px;
    }
    .top-nav ul li a {
        padding: 0 6px;
    }
    .mind-header .logo {
        margin-top: 40px;
    }
    .navigation .nav > li{
        padding: 0 15px;
    }
    .last-header {
        margin-top: 40px;
    }
    .audi-welke-band {
        padding: 50px 0;
    }
    .audi-welke-band-left h1 {
        margin-bottom: 30px;
    }
    .audi-zoeken {
        padding: 30px;
    }
    .cars-repairs {
        margin-top: 50px;
    }
    .cars-repairs h3 {
        margin-bottom: 30px;
    }
    .cars-repairs p{
        margin-bottom: 30px;
    }
    .prevent-oil {
        margin-bottom: 50px;
        margin-top: 50px;
    }
    .need-help-sec .hedding p {
        font-size: 14px;
    }
    .footer-colum{
        margin-bottom: 30px;
    }
    .footer{
        padding-bottom: 30px;
    }
}
@media (max-width: 767px){
    .maak-top{
        display: none;
    }
    .mind-header .logo {
        display: block;
    }
    .top-header .socail-icons {
        margin-top: 0;
    }
    .top-nav {
        margin-top: 0;
        text-align: right;
    }
    .navigation .nav > li {
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .last-header {
        margin-top: 0;
        position: absolute;
        right: 15px;
        top: 100px;
        width: 100%;
        z-index: 1;
        border: none;
    }
    .logo img{
        position: relative;
        width: 200px;
        z-index: 2;
    }
    .navigation .navbar-default .navbar-collapse, .navbar-default .navbar-form {
        background-color: #fff;
        border-color: #e7e7e7;
        position: absolute;
        top: 45px;
        width: 100%;
        right: -15px;
    }
    .navigation .navbar-nav {
        display: block;
        text-align: left;
    }
    .navigation .nav > li > a {
        padding: 10px 0;
        font-size: 15px;
    }
    .navigation .navbar-toggle {
        background-color: #035992;
        border: none;
        border-radius: 0;
    }
    .navigation .navbar-default .navbar-toggle .icon-bar {
        background-color: #fff;
    }
    .navbar-default .navbar-toggle:focus, .navbar-default .navbar-toggle:hover {
        background-color: #035992;
    }
    .audi-zoeken .form-group {
        display: inline-block;
        margin-bottom: 0;
    }
    .onderhoud-catesories {
        margin-bottom: 30px;
        padding: 10px;
    }
    .onderhoud-sec {
        padding: 50px 0;
    }
    .about-sec {
        padding: 50px 0;
    }
    .about-inner .hedding {
        padding-bottom: 30px;
    }
    .about-inner .text-box{
        margin-top: 30px;
    }
    .meet-the-owners-inner{
        margin-bottom: 30px;
    }
    .about-cntant {
        margin-top: 20px;
    }
    .Meet-the-owners {
        margin-top: 50px;
    }
    .inner-banner .image-holder img {
        height: 400px;
        object-fit: cover;
        width: 100%;
    }
    .text-inner {
        padding: 15px;
    }
    .text-inner p {
        font-size: 14px;
    }
    .inner-banner .text-box {
        top: 60%;
    }
}
@media (max-width: 640px){
    .audi-zoeken {
        padding: 30px;
    }
    .audi-zoeken h3 {
        margin-bottom: 15px;
        padding-bottom: 15px;
    }
    .audi-zoeken .form-group {
        display: block;
        margin-bottom: 15px;
    }
    .audi-zoeken form .form-group select {
        margin-right: 0;
        padding: 12px 25px 12px 5px;
        width: 100%;
        background: #ebecee url("../images/select-arrow.jpg") no-repeat scroll 98% center;
    }
}
@media (max-width: 600px){
    .prevent-oil .text-box {
        float: none;
        padding: 5% 0;
        width: 100%;
    }
    .prevent-oil .image-holder {
        float: none;
        width: 100%;
    }
    .prevent-oil .image-holder img{
        width: 100%;
    }
    .onderhoud-start h2 {
        margin-bottom: 20px;
    }
}
@media (max-width: 550px){
    h1 {
        font-size: 24px;
    }
    h2{
        font-size: 20px;
    }
    .top-header .socail-icons ul li {
        margin-right: 3px;
    }
    .inner-banner .image-holder img {
        height: 300px;
        object-fit: cover;
        width: 100%;
    }
    .text-inner {
        padding: 15px;
    }
    .text-inner p {
        font-size: 14px;
    }
    .audi-welke-band-left h1 {
        margin-bottom: 20px;
    }
    .footer-inner {
        padding-bottom: 50px;
        padding-top: 50px;
    }
}
@media (max-width: 479px){
    .navigation .navbar-toggle {
        position: absolute;
        top: -122px;
        left: 20px;
        z-index: 500;
    }
    .logo img {
        z-index: -1;
    }
    .top-header .col-xs-3,
    .top-header .col-xs-9{
        width: 100%;
    }
    .top-header .socail-icons {
        text-align: center;
    }
    .top-nav {
        margin-top: 10px;
        text-align: center;
    }
    .top-header .socail-icons ul li {
        margin: 0 5px;
    }
    .mind-header .logo {
        margin-top: 20px;
    }
    .last-header {
        top: 120px;
    }
    .navigation .navbar-default .navbar-collapse, .navbar-default .navbar-form {
        top: -78px;
    }
    .cars-repairs h3 {
        margin-bottom: 15px;
    }
    .help-cal a {
        margin-right: 5px;
        padding: 10px 15px;
    }
    .footer .col-xs-6{
        width: 100%;
    }
    .meet-the-owners-inner .image-holder,
    .meet-the-owners-inner .text-clints{
        width: 100%;
        float: none;
    }
    .meet-the-owners-inner .image-holder img{
        width: 100%;
    }
}
@media (max-width: 379px){
    .top-nav ul li a {
        font-size: 12px;
        padding: 0 2px;
    }
}






















